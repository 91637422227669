import * as React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import media from '../styles/media';
import { font } from '../styles/fonts';

interface HeroProps {
  image?: string;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  font-family: Poppins;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 8vw;
  line-height: 1.4;
  color: #0038e3;
  padding: 0 18vw;

  overflow: hidden;
  position: relative;

  ${media.tablet`
    line-height: 1.2;
    font-size: 5vw;
    font-weight: normal;
  `};

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
  }

  .content {
    position: relative;
    z-index: 10;
  }
`;

const BackgroundImage = styled(Img).attrs({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})``;

const Hero = (props: HeroProps) => {
  return (
    <Wrapper>
      <BackgroundImage {...props.image} />

      <div className="content">{props.children}</div>
    </Wrapper>
  );
};

export default Hero;
