import styled from 'styled-components';
import { font } from '../styles/fonts';

export const ButtonLink = styled.a`
  background: #ffffff;
  border: 0;
  border-radius: 6px;

  ${font({
    size: 14,
    lineHeight: 22.5,
    weight: 400,
  })}

  color: #0038e3;

  text-decoration: none;

  padding: 1em 2em;
`;

export const Button = ButtonLink.withComponent('button');
