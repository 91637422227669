import styled from 'styled-components';

import { font } from '../styles/fonts';

export default styled.h1`
  ${font({
    size: 20,
    weight: 700,
    lineHeight: 41,
  })}
  color: #0038e3;
  letter-spacing: 0;
  text-align: left;
  padding: 0 2rem;
`;
