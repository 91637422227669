import styled from 'styled-components';

import media from '../styles/media';
import { font } from '../styles/fonts';

export default styled.p`
  ${font({
    size: 20,
    lineHeight: 26,
    weight: 700,
  })}
  color: #222222;
  text-align: center;
  padding: 0 2rem;

  ${media.tablet`
    ${font({
      size: 40,
      lineHeight: 52,
      weight: 700,
    })}
  `};
`;
