import styled from 'styled-components';

import { maxWidthWrap } from './max-width-wrapper';

export default styled.div<{ bigger?: boolean }>`
  background: white;

  ${maxWidthWrap};

  ${({ bigger }) => (bigger ? 'max-width: 1280px;' : '')}

  margin: -100px auto 0;
  padding: 1rem 0;

  position: relative;
  z-index: 10;
`;
