import * as React from 'react';
import Helmet from 'react-helmet';

import image from '../../images/meta.jpg';

import './index.css';

const name = 'Gruppo C.A.T. srl';
const description =
  'Gruppo C.A.T. srl opera da oltre venti anni nel settore ricezione satellitare.';

export const TemplateWrapper = ({ children }) => (
  <div id="root">
    <Helmet
      title={name}
      meta={[
        {
          name: 'description',
          content: description,
        },

        { name: 'twitter:site', content: name },
        { name: 'twitter:creator', content: name },
        { name: 'twitter:title', content: name },
        { name: 'twitter:image', content: image },

        { property: 'og:title', content: name },
        { property: 'og:site_name', content: name },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://gruppocat.eu' },
        { property: 'og:description', content: description },
        { property: 'og:image', content: image },
        {
          name: 'google-site-verification',
          content: 'tHrvNjpD1EEn7VrTuceQRaa1XQ_ID8KAk5odEx6GM-4',
        },
        {
          name: 'theme-color',
          content: '#002DDA',
        },
      ]}
      link={[
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/favicons/favicon-16x16.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/favicons/favicon-32x32.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '36x36',
          href: '/favicons/android-chrome-36x36.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '48x48',
          href: '/favicons/android-chrome-48x48.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '72x72',
          href: '/favicons/android-chrome-72x72.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: '/favicons/android-chrome-96x96.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '144x144',
          href: '/favicons/android-chrome-144x144.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '256x256',
          href: '/favicons/android-chrome-256x256.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '384x384',
          href: '/favicons/android-chrome-384x384.png',
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '512x512',
          href: '/favicons/android-chrome-512x512.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          href: '/favicons/apple-touch-icon-57x57.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          href: '/favicons/apple-touch-icon-60x60.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          href: '/favicons/apple-touch-icon-72x72.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          href: '/favicons/apple-touch-icon-76x76.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          href: '/favicons/apple-touch-icon-114x114.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          href: '/favicons/apple-touch-icon-120x120.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          href: '/favicons/apple-touch-icon-144x144.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          href: '/favicons/apple-touch-icon-152x152.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/favicons/apple-touch-icon-180x180.png',
        },
      ]}
    />
    {children}
  </div>
);