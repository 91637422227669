import * as React from 'react';

import styled from 'styled-components';

import MaxWidthWrapper from './max-width-wrapper';
import { font } from '../styles/fonts';

const Footer = styled.footer`
  ${font({
    size: 14,
    lineHeight: 22.5,
    weight: 400,
  })}

  color: white;
  background: #222222;
`;

const Wrapper = styled(MaxWidthWrapper)`
  padding: 0 2rem;

  display: flex;

  justify-content: space-between;
  align-items: flex-start;

  padding: 2rem;
`;

export default ({ children }: { children?: React.ReactNode }) => (
  <Footer>
    <Wrapper>{children}</Wrapper>
  </Footer>
);
