import * as React from 'react';

import styled from 'styled-components';

import MaxWidthWrapper from './max-width-wrapper';
import { font } from '../styles/fonts';

const Bar = styled.div`
  ${font({
    size: 16,
    weight: 700,
    lineHeight: 22.5
  })}

  color: white;
  background: #0038e3;
`;

const Wrapper = styled(MaxWidthWrapper)`
  padding: 0 2rem;

  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: 2rem;
`;

export default ({ children }: { children?: React.ReactNode }) => (
  <Bar>
    <Wrapper>{children}</Wrapper>
  </Bar>
);
