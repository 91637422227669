import { css } from 'styled-components';

type FontArgs = {
  size: number;
  weight: 400 | 700;
  lineHeight: number;
};

export const font = ({ size, weight, lineHeight }: FontArgs) => css`
  font-family: Poppins;
  font-size: ${size}px;
  font-weight: ${weight};
  line-height: ${lineHeight}px;
`;
