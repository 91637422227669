import styled from 'styled-components';
import { font } from '../styles/fonts';

export default styled.p`
  ${font({
    size: 16,
    lineHeight: 25.6,
    weight: 400,
  })}

  padding: 0 2rem;
  margin-bottom: 1.6em;
`;
