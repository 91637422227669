import { css } from 'styled-components';

const sizes = {
  tablet: 768,
  desktop: 992,
};

type MediaTemplate = { [P in keyof typeof sizes]: typeof css };

// TODO: suppress error: https://github.com/Microsoft/TypeScript/pull/18457

const media = Object.keys(
  sizes
).reduce((acc: MediaTemplate, label: keyof MediaTemplate) => {
  acc[label] = (...args: any[]) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default media as MediaTemplate;
