import * as React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import styled from 'styled-components';

import Header from '../components/header';
import Hero from '../components/hero';
import Section from '../components/section';
import SectionTitle from '../components/section-title';
import Paragraph from '../components/paragraph';
import SectionIntro from '../components/section-intro';
import LocationBox from '../components/location-box';
import BigBar from '../components/big-bar';
import { ButtonLink } from '../components/button';
import Footer from '../components/footer';
import FooterTitle from '../components/footer-title';
import { TemplateWrapper } from '../components/wrapper';

const Locations = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0 0;
`;

const FullscreenImage = styled(Img).attrs({
  style: {
    width: '100%',
    height: '90vh',
  },
})``;

const FooterInfo = styled.address`
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva,
    Verdana, sans-serif;
  font-style: normal;
  font-size: 1rem;
  color: #999999;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0 0 0.5em;
`;

const LegalText = styled.p`
  font-size: 0.8em;
  line-height: 1.2;
  a {
    color: white;
  }
`;

const IndexPage = ({ data }: { data: any }) => (
  <TemplateWrapper>
    <Header>Gruppo C.A.T. srl</Header>
    <Hero image={data.hero.childImageSharp}>
      Tutto per la TV via Satellite (e&nbsp;non&nbsp;solo)
    </Hero>

    <Section>
      <SectionTitle>Chi Siamo</SectionTitle>
      <SectionIntro>
        Gruppo C.A.T. srl opera da oltre venti anni nel settore ricezione
        satellitare.
      </SectionIntro>
      <br />
      <br />
      <br />
    </Section>

    <Section bigger>
      <Locations>
        {data.shops.edges.map(({ node }) => (
          <LocationBox
            key={node.id}
            name={node.frontmatter.name}
            address={node.frontmatter.address}
            city={node.frontmatter.city}
            phone={node.frontmatter.phone}
            link={node.frontmatter.link}
            image={node.frontmatter.image.childImageSharp}
          />
        ))}
      </Locations>
    </Section>

    <FullscreenImage {...data.ethernet.childImageSharp} />

    <Section>
      <SectionTitle>Cosa Facciamo</SectionTitle>
      <Paragraph>
        Il Gruppo C.A.T. è da oltre 20 anni punto di riferimento per Avellino e
        provincia. Un Team di tecnici specializzati nel settore delle
        telecomunicazioni, nel mercato delle connessioni a banda larga, nella
        distribuzione di impianti terrestri e satellitari, centralizzati,
        singoli, fibra ottica, sicurezza, videosorveglianza; Vendita al
        dettaglio e ingrosso. C.A.T. snc è Sky Service, Linkem, Fastweb e
        Opensky.
      </Paragraph>

      <SectionTitle>Sky Service</SectionTitle>
      <Paragraph>
        Tre punti vendita presenti a Benevento, Avellino e provincia. Un luogo
        altamente professionale nel quale potrai avere supporto commerciale per
        nuove sottoscrizioni o per arricchire il tuo abbonamen- to, dove poter
        testare tutte le nuove teconologie Sky e dove ricevere consulenza per
        assistenza tecnica, sostituzione decoder e/o smartcard, installazione
        parabola e assistenza @ 360° su impianti satellitari centralizzati.
      </Paragraph>

      <SectionTitle>Tiscali</SectionTitle>
      <Paragraph>
        Tiscali è una delle principali società di telecomunicazioni in Italia e
        offre ai suoi clienti, privati e aziende, una vasta gamma di servizi di
        comunicazione: accesso a Internet in modalità Fibra & DSL, Voce, VoIP,
        servizi a valore aggiunto, servizi di telefonia mobile.
      </Paragraph>

      <SectionTitle>Linkem</SectionTitle>
      <Paragraph>
        Linkem è internet veloce senza limiti e senza linea fissa anche nelle
        aree dove non arrivano gli altri operatori.
      </Paragraph>

      <SectionTitle>Fastweb</SectionTitle>
      <Paragraph>
        Un progetto assolutamente innovativo dal punto di vista industriale e
        tecnologico: la costruzione di una rete in fibra ottica e l’adozione del
        protocollo IP per la trasmissione di voce, dati e video.
      </Paragraph>

      <SectionTitle>Consorzio Professional Group</SectionTitle>
      <Paragraph>
        L'unione fà la forza; Il Gruppo C.A.T. è associato al Consorzio
        Professional Group.
      </Paragraph>

      <SectionTitle>Project Group Italy</SectionTitle>
      <Paragraph>
        Il Gruppo C.A.T. è socio fondatore di PG Italy , la più grande rete di
        professionisti digitali nella vendita, gestione, installazione e manu-
        tenzione di impianti elettrici, cablatura e sistemi di ricezione tv con
        oltre 25 anni di esperienza sul territorio nazionale, nata per soddi-
        sfare le esigenze del cliente finale e migliorarne la qualità di vita
        attraverso soluzioni all’avanguardia.
      </Paragraph>

      <SectionTitle>Cenni Storici</SectionTitle>
      <Paragraph>
        Il Gruppo C.A.T. srl nasce nel 1999 come C.A.T. snc da un imprenditore
        con ventennale esperienza nel settore di impianti terrestre-satellitari.
        Le competenze maturate gli hanno consentito di espandere l’attività su:
        Avellino, Bisaccia e Benevento i tre punti vendita Gruppo C.A.T. di
        riferimento per l’installatore e per l’utente finale. I negozi sono
        strutturati molto bene, con una vasta gamma di decoder, parabole,
        antenna terrestre e tanti accessori, dunque tutto quello che serve per
        la realizzazione di impianti d’antenna. L’esperienza e le varie
        competenze consentono di soddisfare tutte le esigenze del mercato,
        nonché di fornire i servizi legati alla distribuzione. Supporto al
        cliente. L'obiettivo principale del Gruppo C.A.T è di aumentare
        costantemente la qualità del servizio destinato ai suoi clienti,
        attraverso prodotti qualitativi, prezzi competitivi e servizio
        post-vendita. Inoltre il Gruppo C.A.T offre la formazione sia per
        prodotti e per installazioni.
      </Paragraph>
    </Section>

    <BigBar>
      <p>Hai bisogno di un preventivo?</p>
      <ButtonLink href="mailto:info@gruppocat.eu">Contattaci</ButtonLink>
    </BigBar>

    <Footer>
      <FooterTitle>Gruppo C.A.T. srl</FooterTitle>

      <div>
        <FooterTitle>Trovaci</FooterTitle>

        <FooterInfo>
          <address>
            Via Catullo, 15 <br /> 83044 Bisaccia (AV) <br /> Tel. 0827/81236
          </address>
          <p>P.IVA 02138960642</p>
        </FooterInfo>
      </div>
    </Footer>
    <Footer>
      <LegalText>
        Gli aiuti di Stato e gli aiuti de minimis ricevuti dalla nostra impresa
        sono contenuti nel Registro nazionale degli aiuti di Stato di cui
        all'art. 52 della L. 234/2012 a cui si rinvia e consultabili al seguente
        link{' '}
        <a href="https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/Trasparenza">
          https://www.rna.gov.it/RegistroNazionaleTrasparenza/faces/pages/Trasparenza
        </a>
      </LegalText>
    </Footer>
  </TemplateWrapper>
);

export const pageQuery = graphql`
  query HomePage {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ethernet: file(relativePath: { eq: "ethernet.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    shops: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/shops/\\w+\\.md$/i" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            address
            city
            phone
            link
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
