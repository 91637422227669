import * as React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import media from '../styles/media';
import { font } from '../styles/fonts';

const Title = styled.h1`
  ${font({
    size: 16,
    weight: 700,
    lineHeight: 17.6,
  })}
  color: #222222;
  margin: 0 0 0.5em;
`;

const Address = styled.address`
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva,
    Verdana, sans-serif;
  font-style: normal;
  font-size: 1.5em;
  color: #999999;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0 0 0.5em;
`;

const Wrapper = styled.div`
  position: relative;
  font-size: 8px;
  flex: 100% 0 0;

  margin-bottom: 100px;

  ${media.tablet`
    width: 24%;
    flex: 24% 0 0;
  `};
`;

const Image = styled(Img).attrs({
  style: {
    width: '100%',
    height: 250,
  },
})`
  margin: 0;

  > div {
    width: 100%;
    height: auto;
  }
`;

const Content = styled.div`
  background: white;
  position: absolute;
  bottom: -50px;
  right: 0;
  width: 80%;

  padding: 1rem;
`;

const Link = styled.a`
  ${font({
    size: 11.2,
    lineHeight: 26.1,
    weight: 700,
  })}
  color: #0038e3;
  text-decoration: none;
`;

interface LocationProps {
  name: string;
  address: string;
  city: string;
  phone: string;
  link: string;
  image: any;
}

const Box: React.StatelessComponent<LocationProps> = ({
  image,
  name,
  address,
  city,
  phone,
  link,
}) => (
  <Wrapper>
    <Image fluid={image.fluid} />

    <Content>
      <Title>{name}</Title>

      <Address>
        {address}
        <br />
        {city}
        <br />
        {phone}
      </Address>

      <Link href={link} target="_blank">
        Trovaci →
      </Link>
    </Content>
  </Wrapper>
);

export default Box;
