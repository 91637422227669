import styled, { css } from 'styled-components';

export const maxWidthWrap = css`
  margin: 0 auto;
  max-width: 960px;
`;

export default styled.div`
  ${maxWidthWrap}
`;
